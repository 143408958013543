import App from "./components/App";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import React from "react";
import { createRoot } from "react-dom/client";
/* global document, Office, module, require */

initializeIcons();

let isOfficeInitialized = false;

const title = "RTDIP Add-in";

const rootElement = document.getElementById("container");
const root = rootElement ? createRoot(rootElement) : undefined;

const renderApp = (Component) => {
  root?.render(
    <FluentProvider theme={webLightTheme}>
      <Component title={title} isOfficeInitialized={isOfficeInitialized} />
    </FluentProvider>,
    document.getElementById("container")
  );
};

/* Initial render showing a progress bar */
renderApp(App);

/* Render application after Office initializes */
Office.onReady(() => { //NOSONAR
  isOfficeInitialized = true;
  renderApp(App);
});


if (module.hot) {
  module.hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    renderApp(NextApp);
  });
}
