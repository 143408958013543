import React, {useState, useEffect} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import EditIcon from '@mui/icons-material/Edit';
import LoopIcon from '@mui/icons-material/Loop';
import {findFormulas, clearCellContent, selectCell, refreshCell, parseFormulaString, addSheetIfNotPresent} from '../../utils/ExcelUtils';
import {CUSTOM_FUNCTION_PARAMETERS, CUSTOM_FUNCTION_NAME_MAPPING, CUSTOM_FUNCTION_DEFAULT_PARAMS} from '../../../../constants';

/**
 * Represents a Dashboard component.
 * @memberof module:Taskpane/Components
 * @param {Object} props - The props object.
 * @param {Object} props.formulaData - The formula data.
 * @param {Array} props.formulaData.formulas - The array of formulas.
 * @param {string} props.formulaData.formulas[].address - The address of the formula.
 * @param {string} props.formulaData.formulas[].content - The content of the formula.
 * @param {boolean} props.formulaData.formulas[].error - Indicates if the formula has an error.
 * @param {Object} props.formulaData.formulas[].parsedParameters - The parsed parameters of the formula.
 * @param {string} props.formulaData.sheetName - The name of the sheet.
 * @param {Function} props.setFormulaData - The function to set the formula data.
 * @param {Function} props.handleEdit - The function to handle formula editing.
 * @param {Function} props.handleError - The function to handle errors.
 * @returns {JSX.Element} The rendered Dashboard component.
 * 
 * @example
 * return <Dashboard formulaData={formulaData} setFormulaData={setFormulaData} handleEdit={handleEdit} handleError={handleError}/>
 */
function Dashboard(props) {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)

        const findFormulasEffect = async () => {

        // Get all formulas in the sheet in format {formulas: [{address: "A1", content: "=RTDIP.RAW()"}], sheetName: "Sheet1"}
        const foundFormulaData = await findFormulas();

        // Try to parse the formulas 
        foundFormulaData["formulas"].forEach(item => {
          try {
            const paramsAndSettings = parseFormulaString(item["content"], foundFormulaData["sheetName"], CUSTOM_FUNCTION_PARAMETERS, CUSTOM_FUNCTION_NAME_MAPPING)
            item["parsedParameters"] = paramsAndSettings["params"]
          } catch (e) {
            console.log(e)
          }
        })

        // Check if the formula has an error in its value on th sheet
        foundFormulaData["formulas"].forEach(item => {
          try{
            const formulaValue = item["value"]
            // if first characters are "Error:" or the Excel VALUE or CALC error, then show error
            const isExcelError = formulaValue == "#VALUE!" || formulaValue == "#CALC!"
            const isFunctionError = typeof formulaValue == "string" && formulaValue.slice(0, 6) == "Error:"
            if (isExcelError || isFunctionError) {
              item["error"] = true
            } else {
              item["error"] = false
            }
          } catch (e) {
            console.log(e)
          }
        })

        // check existing formulas, and ensure to keep those that are still valid (i.e. exact same formula)
        // the user may have edited the formula in the cell and we should ensure update the state
        const newFormulas = []
        for (let i = 0; i < foundFormulaData["formulas"].length; i++) {
          const item = foundFormulaData["formulas"][i]
          // check if the formula is still the same
          const found = props.formulaData.formulas.find(formula => formula["address"] == item["address"])
          const identical = found && found["content"] == item["content"] && found["error"] == item["error"]
          if (found && identical) {
            newFormulas.push(found)
          } else {
            newFormulas.push(item)
          }
        }

        // set the state of the formulas
        props.setFormulaData({
          formulas: newFormulas,
          sheetName: foundFormulaData["sheetName"]
        })
        
        setLoading(false)
      }

      findFormulasEffect()
    }, [])

    const handleAccordianClick = async (e, cellName) => {
      selectCell(cellName)
    }

    const handleDelete = async (e, item) => {
      // delete the formula from the cell
      await clearCellContent(item["address"])

      // remove the formula from the state
      props.setFormulaData({
        ...props.formulaData,
        formulas: props.formulaData.formulas.filter(formula => formula["address"] != item["address"])
      })

    }

    const handleEdit = async (e, item) => {
      
      try{
        const address = addSheetIfNotPresent(item["address"], props.formulaData["sheetName"])
        // replace all null/undefined values with default values
        for (const key in item["parsedParameters"]) {
          if(item["parsedParameters"][key] == null) {
            item["parsedParameters"][key] = CUSTOM_FUNCTION_DEFAULT_PARAMS[key]
            }
        }

        props.handleEdit(address, item["parsedParameters"])
      } catch (e) {
        console.log(e)
        props.handleError("This formula cannot be edited with the form.Please edit manually in the formula bar.")
      }
      
    }

    const handleRefresh = async (e, item) => {
      refreshCell(item["address"], item["content"])
    }


  return (
    <div className='dashboard'>
      
      {props.formulaData.formulas.length == 0 && 
        <div className="dashboard-hint">

          <div className="dashboard-try">
            <p>Try create a query with Auto Query or a raw SQL statement: </p>
            <p className="dashboard-try-query">SELECT * FROM rtdip.sensors.test_table_float LIMIT 10</p>
          </div>

          <p>All current uses of the RTDIP Add-in from this sheet will be shown here</p>

        </div>
      }
    
      {props.formulaData.formulas.map( (item, idx) => 
          <Accordion key={idx} onClick={(e) => handleAccordianClick(e, item["address"])}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className='dashboard-item'
            >
              <p className='dashboard-item-cell'>{item["address"]}</p>
              <p className='dashboard-item-formula'>{item["content"].match(/\.(\w+)\(/)[1]}</p>

              {item["error"] &&
                <div className={`dashboard-item-time`}>
                  <p>Error</p>
                </div>
              }

              {item["parsedParameters"] && item["parsedParameters"]["refreshInterval"] && !item["error"] &&
                <div className={`dashboard-item-time dashboard-pulse-button`}>
                  <p>Streaming</p>
                </div>
              }
            </AccordionSummary>
            <AccordionDetails className='dashboard-accordian-details'>
                  <IconButton  color="error" type="submit" data-testid={`formula-delete-${idx}`} onClick={(e) => handleDelete(e, item)}>
                      <DeleteIcon/>
                  </IconButton>
                  <IconButton  color="primary" type="submit" data-testid={`formula-edit-${idx}`} onClick={(e) => handleEdit(e, item)}>
                      <EditIcon />
                  </IconButton>
                  <IconButton  color="secondary" type="submit" data-testid={`formula-refresh-${idx}`} onClick={(e) => handleRefresh(e, item)}>
                      <AutorenewIcon />
                  </IconButton>
            </AccordionDetails>
          </Accordion>
      )}

      {loading && 
        <div className='dashboard-loading'>
          <LoopIcon className='input-icon'/>
        </div>
      }
   
    </div>
  );
}

export default Dashboard;