import React from 'react';
import { Button, Menu, MenuItem, Typography, styled } from '@mui/material';


// Create a styled version of the Paper component that Menu uses
const CustomScrollMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxHeight: 300, 
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#dad7d7',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: "transparent",
    }
  },
}));


/**
 * ListButton component.
 * @memberof module:Taskpane/Components
 * @param {Object} props - The component props.
 * @param {Array} props.options - The list of options for the button.
 * @param {boolean} props.enabled - Indicates if the button is enabled or disabled.
 * @param {string} props.value - The current value of the button.
 * @param {string} props.label - The label to display when the button value is empty.
 * @param {Function} props.handleSelection - The callback function to handle the selection of an option.
 * 
 * @returns {JSX.Element} The rendered ListButton component.
 * 
 * @example
 * return <ListButton options={["Option1", "Option2"]} enabled={true} value="Option1" label="Select an option" handleSelection={handleSelection}/>
 */
function ListButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e, value) => {
    setAnchorEl(null);

    // guard against other inputs
    if(props.options.includes(value)){
        props.handleSelection(value)
    }
    
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled={!props.enabled}
        sx={{
          color: props.value.length > 0 ? 'black' : 'blueviolet',
          textTransform: 'none',
          "&:hover": {
            backgroundColor: "rgba(200, 200, 200, 0.1)"
          }
        }}
      >
        <Typography noWrap sx={{ fontSize: 14, maxWidth: 100, textOverflow: "ellipsis", overflow: "hidden"}}>
          {props.value.length > 0 ? props.value : props.label}
        </Typography>
      </Button>
      <CustomScrollMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: { maxHeight: 200, paddingTop: 0, paddingBottom: 0 },
        }}
      >
        {props.options.map( (option, idx) =>
            <MenuItem 
              key={idx}
              sx={{fontSize: 14, paddingTop: 0, paddingBottom: 0, minHeight: 35}}
              onClick={(e) => handleClose(e, option)}
            >
              {option}
            </MenuItem>
        )}
      </CustomScrollMenu>
    </div>
  );
}


export default ListButton;