import React from 'react'
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


/**
 * Renders the UpdateScreen component.
 * @memberof module:Taskpane/Components
 * @param {Object} props - The component props.
 * @param {Function} props.handleUpdateClose - The function to handle the update screen close event.
 * @returns {JSX.Element} The rendered UpdateScreen component.
 */
export default function UpdateScreen(props) {

  const handleClose = () => {
    props.handleUpdateClose()
  }

  const fontStyle = {style: {fontSize: 14}}

  return (
    <Dialog open={true} fullScreen >

        <div style={{marginTop: "50px", marginBottom: "0px"}}>
            <div className="welcome-image-container">
                <img src="./../../../assets/rtdip-horizontal-color.png"></img>
            </div>
        </div>  

    {/* Content */}
      <div style={{padding: "20px 20px", height: "100%"}}>     

        <div style={{backgroundColor: "aliceblue", borderRadius: "20px", padding: "30px"}}>
            <Typography>Update:</Typography>
            <br></br>
            <ul>
              <li>Simplified inputs, including optional table names</li>
              <li>Updated dashboard: Improved parsing, error indication, and streaming status</li>
              <li>Added automatic refresh capability at set intervals</li>
              <li>Performance Improvements for when many cells update</li>
              <li>Bug fixes</li>
            </ul>
            <br></br>
        </div>

        <div style={{paddingTop: "30px", display: "flex", flexDirection: "column", alignItems: "center"}}>
          <Button 
            onClick={handleClose}
            variant="contained"
            color="secondary"
            sx={{
              width: "200px",
              backgroundColor: "blueviolet",
              "&:hover": {
                backgroundColor: "blueviolet",
                opacity: 0.9
              }
            }}>
            Continue
          </Button>
        </div>

      </div>
      
      


    </Dialog>
  );
}
