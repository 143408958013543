import { useState, useEffect } from 'react';

/**
 * Custom hook for managing state in local storage.
 * @memberof module:Taskpane/Hooks
 * @param {string} key - The key to use for storing the value in local storage.
 * @param {any} defaultValue - The default value to use if no value is found in local storage.
 * @returns {Array} - An array containing the current value and a function to update the value.
 */
const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    try {
      return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
    } catch (error) {
      return storedValue !== null ? storedValue : defaultValue; // Return raw string if JSON.parse fails
    }
  });

  useEffect(() => {
    if (typeof value === 'string') {
      localStorage.setItem(key, value); // Store as raw string if it's a string
    } else {
      localStorage.setItem(key, JSON.stringify(value)); // Store as JSON if it's not a string
    }
  }, [key, value]);

  return [value, setValue];
};

export default useLocalStorage;
