import useLocalStorage from "./useLocalStorage";

/**
 * Custom hook for managing settings with local storage.
 * @memberof module:Taskpane/Hooks
 * @returns {Array} An array containing the current settings object and a function to update the settings.
 */
const useSettings = () => {
    // Local storage hooks
    const [apiUrl, setApiUrl] = useLocalStorage('apiUrl', "");
    const [hostname, setHostname] = useLocalStorage('hostname', "");
    const [httpPath, setHttpPath] = useLocalStorage('httpPath', "");
    const [includeHeaders, setIncludeHeaders] = useLocalStorage('includeHeaders', "true");
    const [headerSettings, setHeaderSettings] = useLocalStorage('headerSettings', {backgroundColor: "blueviolet", textColor: "#ffffff"});
    const [dateSettings, setDateSettings] = useLocalStorage('dateSettings', "user-timezone");

    
    const setSettingsLocalStorage = async (newSettings) => {
      try {
        localStorage.setItem('hostname', newSettings["hostname"]);
        localStorage.setItem('httpPath', newSettings["httpPath"]);
        localStorage.setItem('apiUrl', newSettings["apiUrl"]);
        localStorage.setItem('includeHeaders', newSettings["includeHeaders"]);
        localStorage.setItem('headerSettings', JSON.stringify(newSettings["headerSettings"]));
        localStorage.setItem('dateSettings', newSettings["dateSettings"]);

      } catch(error) {
        console.error(error);
      }

    }
    
    const updateSettings = async (newSettings) => {
      try {
        // Set in local storage then update state
        setSettingsLocalStorage(newSettings).then( () => {
            setHostname(newSettings["hostname"])
            setHttpPath(newSettings["httpPath"])
            setApiUrl(newSettings["apiUrl"])
            setIncludeHeaders(newSettings["includeHeaders"])
            setHeaderSettings(newSettings["headerSettings"])
            setDateSettings(newSettings["dateSettings"])
        })
        
      } catch (error) {
        console.error('Failed to update settings:', error);
      }
    };
  
    return [
        {
        apiUrl,
        hostname,
        httpPath,
        includeHeaders,
        headerSettings,
        dateSettings
        }, 
        updateSettings
    ];
  };
  
  export default useSettings;