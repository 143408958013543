import React, { useState, useEffect, useRef} from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/joy/Button';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import ListIcon from '@mui/icons-material/List';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import SearchTextField from "../SearchTextField/SearchTextField";
import TableSelector from "../TableSelector/TableSelector";
import TagSearchDialog from "../TagSearchDialog/TagSearchDialog";
import {addClickHandler, removeClickHandler} from '../../utils/ExcelUtils'
import {CUSTOM_FUNCTION_PARAMETERS, FIELD_INPUTS} from '../../../../constants'


/**
 * Represents a form component.
 * @memberof module:Taskpane/Components
 * @param {Object} props - The properties object.
 * @param {Object} props.formData - The form data object.
 * @param {Function} props.handleClick - The click event handler function.
 * @param {Function} props.handleTableCatalogQuery - The table catalog query handler function.
 * @param {Function} props.handleTagSearchQuery - The tag search query handler function.
 * @param {Function} props.handleError - The error handler function.
 * @returns {JSX.Element} The form component.
 * 
 * @example
 * return <Form formData={formData} handleClick={handleClick} handleTableCatalogQuery={handleTableCatalogQuery} handleTagSearchQuery={handleTagSearchQuery} handleError={handleError}/>
 */
function Form(props) {
    const [queryType, setQueryType] = useState("raw")
    const [formData, setFormData] = useState({
        tableName: "",
        tagNames: [],
        startDate: "",
        endDate: "",
        timeInterval: "",
        aggMethod: "first",
        includeBadData: true,
        interpolationMethod: "forward_fill",
        timestamps: [],
        windowLength: 1,
        step: true,
        lowerBound: "",
        upperBound: "",
        outputCell: "Sheet1!A1",
        includeDateColumn: true,
        limit: 1,
        offset: 0,
        valueOnly: false,
        region: "EMEA",
        summaryType: "Avg",
        refreshInterval: ""
    });
    const [outputCell, setOutputCell] = useState("Sheet1!A1")
    const [activeInput, setActiveInput] = useState(null)
    const [statefulEventResult, setStatefulEventResult] = useState(null)
    const [selectedCell, setSelectedCell] = useState(null)
    const [cellClickSeed, setCellClickSeed] = useState(false)

    // Add body click listener to remove active input setting
    const handleBodyClick = (event) => {
        if(event.target.classList.contains("MuiInputBase-input") || event.target.classList.contains("MuiTextField-root")){
            return
        }
        setActiveInput(null)
    };

    useEffect(() => {

        if (Object.keys(props.formData).length > 0) {
            setFormData(props.formData)
            setQueryType(props.formData["query_type"])
            setOutputCell(props.formData["outputCell"])
        }
      }, [props.formData]);


    useEffect(() => { 

        const clickHandlerEffect = async () => {
            // Add click handler to excel on mount so that we can get the selected cell
            let callback = async (cellAddress) => {
                setSelectedCell(cellAddress)
                setCellClickSeed(Math.random())
            }

            let eventResult = await addClickHandler(callback)
            setStatefulEventResult(eventResult)
        }

        document.body.addEventListener('click', handleBodyClick);
        clickHandlerEffect()

        // Define cleanup function for component unmount
        const cleanup = async () => {
            document.body.removeEventListener('click', handleBodyClick);
            if(statefulEventResult != null){
                await removeClickHandler(statefulEventResult)
            }
            setStatefulEventResult(null)
        }

        return cleanup
    }, [])


    useEffect(() => { 

        const cellSelectionEffect = async () => {   
            // If a cell is selected and an input is active, set the value of the input to the selected cell
            if(selectedCell != null && activeInput != null){

                if(activeInput == "outputCell"){
                    setOutputCell(selectedCell)
                } else {
                    setFormData((prevState) => ({
                        ...prevState,
                        [activeInput]: FIELD_INPUTS[activeInput]["inputType"] == "textArray" ? [selectedCell] : selectedCell
                    }))
                }
            }
            setActiveInput(null)
        }

        cellSelectionEffect()

    }, [cellClickSeed])


    const handleSubmit = (e) => {
        e.preventDefault();
        
        // clean up any active input
        setActiveInput(null)

        let paramsToSend = {"mode": "guided", "query_type": queryType, "outputCell": outputCell}

        // Add all required parameters to the paramsToSend object
        CUSTOM_FUNCTION_PARAMETERS[queryType]["required_params"].map(param => {
            if(param !== "apiUrl"){ // api url is not required in the form since taken from settings
                paramsToSend[param] = formData[param]
            }
        })

        // Add all advanced parameters to the paramsToSend object
        CUSTOM_FUNCTION_PARAMETERS[queryType]["advanced_params"].map(param => {
            paramsToSend[param] = formData[param]
        })

        // send the parameter values along with the parameter names as per query type
        props.handleClick(paramsToSend, CUSTOM_FUNCTION_PARAMETERS[queryType]);
    }
 
    // Handle input focus
    const handleInputClick = async (param) => {
        setActiveInput(param)
    };
    
    const handleSelectorClick = () => {
        // clean up any active input in case they are no longer rendered
        setActiveInput(null)
    }
    

    const handleInputChange = (event, field, inputType) => {
        const { value } = event.target;
    
        if (inputType === "textArray") {
            const newArray = value.split(",").map(item => item.trim());
            setFormData((prevState) => ({
                ...prevState,
                [field]: newArray
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [field]: value
            }));
        }
    };
    

    const handleTagSave = (tags) => {
        setFormData((prevState) => ({
            ...prevState,
            tagNames: tags
        }));
    }

    const fontStyle = {style: {fontSize: 14}}

    // Utility function to create form fields based on the query type
    function createFormFields(specificFieldObj, field, value) {

        // Create the input field as per the type
        if(specificFieldObj["inputType"] == "select"){
            const sanitizedLabelForId = specificFieldObj["label"].split(' ').join('-');
            const textOptions = specificFieldObj["uiOptions"] ? specificFieldObj["uiOptions"] : specificFieldObj["options"]
            return (
                <FormControl fullWidth size="small" margin="dense" key={specificFieldObj["label"]}>
                    <InputLabel id={sanitizedLabelForId + "-select-label"}>{specificFieldObj["label"]}</InputLabel>
                    <Select
                        labelId={sanitizedLabelForId + "-select-label"}
                        id={sanitizedLabelForId + "-select"}
                        value={value}
                        label={specificFieldObj["label"]}
                        onChange={(event) => {handleInputChange(event, field, specificFieldObj["inputType"])}}
                    >
                        {specificFieldObj["options"].map((option, idx) => {
                            return <MenuItem value={option} key={idx}>{textOptions[idx]}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            )
        } else {
            return (
                <div style={{display: "flex", flexDirection: "row", alignContent: "center", width: "100%", position: "relative"}} key={specificFieldObj["label"] + "-div"}>
                <TextField  
                    key={specificFieldObj["label"]}
                    label={specificFieldObj["label"]}
                    size="small" 
                    margin="dense"
                    fullWidth={true}
                    inputProps={fontStyle}
                    InputLabelProps={fontStyle}
                    value={specificFieldObj["inputType"] == "textArray" ? value.join(',') : value}
                    onChange={(event) => {handleInputChange(event, field, specificFieldObj["inputType"])}}
                    onFocus={(e) => handleInputClick(field)}
                    focused={activeInput == field}
                />
                {specificFieldObj["tooltip"] &&
                    <Tooltip arrow placement="top" title={specificFieldObj["tooltip"]} >
                        <p style={{position: 'absolute', right: 8}}>
                            <InfoIcon sx={{opacity: 0.7, color: "lightgray", fontSize: "20px"}}/>
                        </p>
                    </Tooltip>
                }
                </div>

            )
        }           
    }

    return (
        <form onSubmit={handleSubmit} data-testid="guided-query-form">
    
            <div className="input-container" key="input-container">

                <FormControl fullWidth size="small" margin="dense" key="query-select">
                    <InputLabel id="query-select-label">Type</InputLabel>
                    <Select
                    labelId="query-select-label"
                    key="query-select"
                    data-testid="query-select"
                    id="query-select"
                    value={queryType}
                    label="Type"
                    onChange={(e) => setQueryType(e.target.value)}
                    onClick={handleSelectorClick}
                    sx={{fontSize: 14}}
                    MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200, // Set your desired max height here
                            overflow: 'auto',
                          },
                          sx: {
                            '&::-webkit-scrollbar': {
                              width: '5px',
                              height: '5px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: '#dad7d7',
                              borderRadius: '10px',
                            },
                            '&::-webkit-scrollbar-track': {
                              background: 'transparent',
                            },
                          },
                        },
                      }}
                    >
                        <MenuItem data-testid="query-select-raw" value={"raw"} sx={{fontSize: 14}}>Raw</MenuItem>
                        <MenuItem value={"resample"} sx={{fontSize: 14}}>Resample</MenuItem>
                        <MenuItem value={"interpolate"} sx={{fontSize: 14}}>Interpolate</MenuItem>
                        <MenuItem value={"interpolation_at_time"} sx={{fontSize: 14}}>Interpolation at Time</MenuItem>
                        <MenuItem value={"time_weighted_average"} sx={{fontSize: 14}}>Time Weighted Average</MenuItem>
                        <MenuItem value={"circular_average"} sx={{fontSize: 14}}>Circular Average</MenuItem>
                        <MenuItem value={"circular_standard_deviation"} sx={{fontSize: 14}}>Circular Standard Deviation</MenuItem>
                        <MenuItem value={"metadata"} sx={{fontSize: 14}}>Metadata</MenuItem>
                        <MenuItem value={"latest"} sx={{fontSize: 14}}>Latest</MenuItem>
                        <MenuItem value={"summary"} sx={{fontSize: 14}}>Summary</MenuItem>
                    </Select>
                </FormControl>


                {CUSTOM_FUNCTION_PARAMETERS[queryType]["required_params"].map(param => {
                    if(param == "tableName") {
                        return(
                                <SearchTextField 
                                    key={FIELD_INPUTS[param]["label"]}
                                    label={FIELD_INPUTS[param]["label"]}
                                    inputProps={fontStyle}
                                    InputLabelProps={fontStyle}
                                    icon={<ListIcon />}
                                    value={formData[param]}
                                    onChange={(event) => {handleInputChange(event, param, FIELD_INPUTS[param]["inputType"])}}
                                    onFocus={(e) => handleInputClick(param)}
                                    focused={activeInput == param}
                                >
                                    <TableSelector 
                                        handleTableCatalogQuery={props.handleTableCatalogQuery}
                                        updateTableName={(val) => {handleInputChange({target: {value: val}}, param, FIELD_INPUTS[param]["inputType"])}}
                                        handleError={props.handleError}
                                        region={formData["region"]}
                                    />
                                </SearchTextField>
                        )

                    } else if(param == "tagNames"){
                        return (
                            <SearchTextField 
                                key={FIELD_INPUTS[param]["label"]}
                                label={FIELD_INPUTS[param]["label"]}
                                inputProps={fontStyle}
                                InputLabelProps={fontStyle}
                                icon={<ManageSearchIcon />}
                                value={formData[param].join(',')}
                                onChange={(event) => {handleInputChange(event, param, FIELD_INPUTS[param]["inputType"])}}
                                onFocus={(e) => handleInputClick(param)}
                                focused={activeInput == param}
                            >
                                <TagSearchDialog
                                    open={true}
                                    currentTags={formData[param]}
                                    handleSave={handleTagSave}
                                    handleTagSearchQuery={props.handleTagSearchQuery}
                                    handleTableCatalogQuery={props.handleTableCatalogQuery}
                                    handleError={props.handleError}
                                    region={formData["region"]}
                                    initialTableName={formData["tableName"]}
                                />
                            </SearchTextField>
                        )
                    } 
                    else if(param !== "apiUrl"){
                        // All other parameters - api url is not required in the form since taken from settings
                        return createFormFields(FIELD_INPUTS[param], param, formData[param])
                    }
                })}

                <TextField 
                    label="Output cell"
                    key="outputCell"
                    size="small"
                    margin="dense"
                    fullWidth={true}
                    inputProps={fontStyle}
                    InputLabelProps={fontStyle}
                    value={outputCell}
                    onFocus={(e) => handleInputClick("outputCell")}
                    onChange={(event) => setOutputCell(event.target.value)}
                    focused={activeInput == "outputCell"}
                />
            </div>

            <div className="button-container">
                <Button className='button-form-submit' endDecorator={<KeyboardArrowRight />} color="info" type="submit">
                Run
                </Button>
            </div>

            {CUSTOM_FUNCTION_PARAMETERS[queryType]["advanced_params"].length > 0 && 
                    <Accordion className='form-advanced-params-accordian'>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            Advanced Parameters
                        </AccordionSummary>

                        <AccordionDetails className='form-advanced-params-accordian-details'>
                            {CUSTOM_FUNCTION_PARAMETERS[queryType]["advanced_params"].map(
                                param => createFormFields(FIELD_INPUTS[param], param, formData[param])
                            )}
                        </AccordionDetails>

                    </Accordion>
                }

        </form>
    )
}


export default Form;