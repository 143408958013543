import React, {useState} from 'react'
import { TwitterPicker } from 'react-color';

function ColorPicker(props) {
    const [showColorPicker, setShowColorPicker] = useState(false)
    
    const handleBlur = (event) => {
        // if click is outside of the color picker, close the color picker
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setShowColorPicker(false)
        }
    };
    
    return (
        <div 
            style={{display: "flex", flexDirection: "row", position: "relative"}}
            onBlur={handleBlur}
        >
            <button style={{
              height: "18px",
              width: "18px",
              backgroundColor: props.color,
              borderRadius: "5px",
              border: "1px solid lightgray",
              cursor: "pointer"
            }}
            onClick={() => {showColorPicker ? setShowColorPicker(false) : setShowColorPicker(true)}}
            />
            <div style={{marginLeft: "10px", fontSize: "12px"}}>{props.label}</div>
            {showColorPicker &&
                <div style={{position: "absolute", top: "25px", zIndex: 2}}>
                    <TwitterPicker
                        color={props.color}
                        onChange={(color) => props.setColor(color.hex, props.label)}
                        // colors={['transparent', '#FFFFFF', 'black', 'blueviolet', '#000E82', '#006B76', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF']}
                        colors={['transparent', '#FFFFFF', 'black', '#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF']}
                        width='80%'
                        triangle='hide'
                    />
                </div>
            }
        </div>
    );
}

export default ColorPicker;