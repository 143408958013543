import React, { useState, useEffect} from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/joy/Button';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from "@mui/material";
import {addClickHandler, removeClickHandler} from '../../utils/ExcelUtils'
import TableSelector from '../TableSelector/TableSelector';

import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-sql';
import 'prismjs/themes/prism.css';
import useLocalStorage from '../../hooks/useLocalStorage';

/**
 * RawQueryForm component for executing raw SQL queries.
 * @memberof module:Taskpane/Components
 * @param {Object} props - The component props.
 * @param {Object} props.sqlFormData - The form data for SQL query.
 * @param {string} props.sqlFormData.sqlQuery - The SQL query string.
 * @param {string} props.sqlFormData.region - The region for executing the query.
 * @param {string} props.sqlFormData.limit - The limit for the query results.
 * @param {string} props.sqlFormData.outputCell - The output cell for the query results.
 * @param {boolean} props.sqlFormData.specifiedWarehouse - Flag indicating whether to use specified SQL Warehouse.
 * @param {Function} props.handleError - The error handling function.
 * @param {Function} props.handleClick - The click handling function.
 * @returns {JSX.Element} The RawQueryForm component.
 * 
 * @example
 * return <RawQueryForm sqlFormData={sqlFormData} handleError={handleError} handleClick={handleClick}/>
 */
function RawQueryForm(props) {
    const [sqlQuery, setsqlQuery] = useState("");
    const [outputCell, setOutputCell] = useState("Sheet1!A1");
    const [limit, setLimit] = useState("1000");
    const [specifiedWarehouse, setSpecifiedWarehouse] = useState(true);
    const [activeInput, setActiveInput] = useState(null)
    const [statefulEventResult, setStatefulEventResult] = useState(null)
    const [selectedCell, setSelectedCell] = useState(null)
    const [region, setRegion] = useState("EMEA")

    useEffect(() => {
        
        if (Object.keys(props.sqlFormData).length > 0) {

            setsqlQuery(props.sqlFormData["sqlQuery"])
            setRegion(props.sqlFormData["region"])
            setLimit(props.sqlFormData["limit"])
            setOutputCell(props.sqlFormData["outputCell"])

            // Specified warehouse is an optional value
            if (props.sqlFormData["specifiedWarehouse"]) {
                setSpecifiedWarehouse(Boolean(props.sqlFormData["specifiedWarehouse"]))
            }
        }
      }, [props.formData]);

      useEffect(() => {
        // If on sql form, check the hostname and http_path are 
        const hostname = props.settings && props.settings["hostname"]
        const httpPath = props.settings && props.settings["httpPath"]
        const hasRequiredSetings = hostname && httpPath

        if(!hasRequiredSetings && specifiedWarehouse){
            props.handleError("Please set the Hostname and HTTP Path in the settings, or uncheck the 'Use specified SQL Warehouse' checkbox.")
        } else {
            props.handleError("reset")
        }
      }
      , [specifiedWarehouse])


      useEffect(() => { 

        const clickHandlerEffect = async () => {
            // Add click handler to excel on mount so that we can get the selected cell
            let callback = (cellAddress) => {
                setSelectedCell(cellAddress)
            }

            let eventResult = await addClickHandler(callback)
            setStatefulEventResult(eventResult)
        }

        clickHandlerEffect()

        // Define cleanup function for component unmount
        const cleanup = async () => {
            if(statefulEventResult != null){
                await removeClickHandler(statefulEventResult)
            }
            setStatefulEventResult(null)
        }

        return cleanup
    }, [])


    // Add body click listener to remove active input setting
        const handleBodyClick = (event) => {
            if(event.target.classList.contains("MuiInputBase-input") || event.target.classList.contains("MuiTextField-root")){
                return
            }
            setActiveInput(null)
        };
    
      useEffect(() => {
        
        document.body.addEventListener('click', handleBodyClick);
    
        // Cleanup function to remove the event listener
        return () => {
          document.body.removeEventListener('click', handleBodyClick);
        };
      }, []);


    useEffect( () => { 
        // If a cell is selected and an input is active, set the value of the input to the selected cell
        if(selectedCell != null && activeInput != null){
            setOutputCell(selectedCell)
        }
        setActiveInput(null)
    }, [selectedCell])

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isNaN(limit) || limit < 0 || limit > 10000000 || limit === "") {
            props.handleError("Limit must be a number between 0 and 10000000")
        } else if (sqlQuery === ""){
            props.handleError("SQL Query cannot be empty")
        } else {
            let paramsToSend = {
                "mode": "raw-query",
                "query_type": "sql",
                "region": region,
                "sqlQuery": sqlQuery.replaceAll("\"", "'"),
                "limit": limit,
                "specifiedWarehouse": specifiedWarehouse,
                "outputCell": outputCell
            }

            const parameterObjLists = {
                "required_params": ["apiUrl", "region", "sqlQuery", "limit"],
                "advanced_params": ["specifiedWarehouse", "refreshIntervalSeconds"],
            }
 
            props.handleClick(paramsToSend, parameterObjLists);
        }
      
    }

    // Handle input focus
    const handleInputClick = async (param) => {
        setActiveInput(param)
    };

    // handle checkbox change
    const handleCheckboxChange = (e) => {
        if(e.target.checked){
            setSpecifiedWarehouse(true)
        } else {
            setSpecifiedWarehouse(false)
        }
    }

    const handleUpdateTableName = (val) => {
        const newQuery = `SELECT * FROM ${val}`
        setsqlQuery(newQuery)
    }

    const fontStyle = {style: {fontSize: 14}}
    return (
        <form onSubmit={handleSubmit} data-testid="sql-query-form">
    
            <div className="input-container" >

                
                <FormControlLabel 
                    control={
                        <Checkbox size="small" checked={specifiedWarehouse} onChange={(e) => {handleCheckboxChange(e)}}/>
                    }
                    
                    label={<Typography sx={{fontSize: 12}}>Use specified SQL Warehouse (settings)</Typography>}
                />

                <FormControl fullWidth size="small" margin="dense" key={"region-select"}>
                    <InputLabel id="region-select-label">Region</InputLabel>
                    <Select
                        labelId="region-select-label"
                        id="region-select"
                        value={region}
                        label="Region"
                        onChange={(event) => setRegion(event.target.value)}
                        sx={{fontSize: 14}}
                    >
                        <MenuItem value={"EMEA"}        sx={{fontSize: 14}}>EMEA</MenuItem>
                        <MenuItem value={"AsiaPac"}     sx={{fontSize: 14}}>AsiaPac</MenuItem>
                        <MenuItem value={"Americas"}    sx={{fontSize: 14}}>Americas</MenuItem>
                    </Select>
                </FormControl>

                <TableSelector 
                    handleTableCatalogQuery={(parameters) => {
                        return props.handleTableCatalogQuery({
                            ...parameters,
                            specifiedWarehouse: specifiedWarehouse
                        })
                    }}
                    updateTableName={handleUpdateTableName}
                    handleError={props.handleError}
                    region={region}
                    specifiedWarehouse={specifiedWarehouse}
                />
                
                <Editor
                    label="SQL Query"
                    textareaId="SQL Query"
                    placeholder='Type a SQL Query...'
                    value={sqlQuery}
                    onValueChange={code => setsqlQuery(code)}
                    highlight={code => highlight(code, languages.sql)}
                    padding={10}
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 14,
                        backgroundColor: "#f5f5f5",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        margin: `5px 0`,
                        width: "100%",
                        height: "200px"
                    }}
                />

                <TextField  
                    label="Limit"
                    size="small" margin="dense"
                    fullWidth={true}
                    multiline
                    inputProps={fontStyle}
                    InputLabelProps={fontStyle}
                    value={limit}
                    error={isNaN(limit) || limit < 0 || limit > 10000000}
                    onChange={(event) => setLimit(event.target.value)}
                />
                <TextField 
                    label="Output cell"
                    size="small"
                    margin="dense"
                    fullWidth={true}
                    inputProps={fontStyle}
                    InputLabelProps={fontStyle}
                    value={outputCell}
                    onFocus={(e) => handleInputClick("outputCell")}
                    focused={activeInput == "outputCell"}
                    onChange={(event) => setOutputCell(event.target.value)}
                />

            </div>

            <div className="button-container">
                <Button endDecorator={<KeyboardArrowRight />} color="info" type="submit">
                    Run
                </Button>
            </div>

        </form>
    )
}


export default RawQueryForm;
