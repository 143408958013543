import React, {useState, useEffect} from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import ColorPicker from './ColorPicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';

/**
 * Renders a settings component.
 * @memberof module:Taskpane/Components
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Indicates whether the settings dialog is open or not.
 * @param {Function} props.handleSettingsChange - The function to handle settings change.
 * @param {Object} props.settings - The current settings object.
 * @returns {JSX.Element} The rendered settings component.
 */
function SettingsDialog(props) {
  const { onClose, open } = props;

  const [hostname, setHostname] = useState(props.settings.hostname)
  const [httpPath, setHttpPath] = useState(props.settings.httpPath)
  const [apiUrl, setApiUrl] = useState(props.settings.apiUrl)
  const [includeHeaders, setIncludeHeaders] = useState(props.settings.includeHeaders)
  const [headerSettings, setHeaderSettings] = useState(props.settings.headerSettings)
  const [dateSettings, setDateSettings] = useState(props.settings.dateSettings);

  useEffect(() => {
    // Populate the inputs with the current settings
    setHostname(props.settings.hostname)
    setHttpPath(props.settings.httpPath)
    setApiUrl(props.settings.apiUrl)
    setIncludeHeaders(props.settings.includeHeaders)
    setHeaderSettings(props.settings.headerSettings)
    setDateSettings(props.settings.dateSettings)
  }, [props])

  const handleClose = () => {
    // If the dialog is closed without submitting, revert the inputs to the inital values
    setHostname(props.settings.hostname)
    setHttpPath(props.settings.httpPath)
    setApiUrl(props.settings.apiUrl)
    setIncludeHeaders(props.settings.includeHeaders)
    setHeaderSettings(props.settings.headerSettings)
    setDateSettings(props.settings.dateSettings)
    onClose();
  };

  const handleSubmit = () => {
    props.handleSubmit({hostname, httpPath, apiUrl, includeHeaders, headerSettings, dateSettings})
    onClose()
  }

  // handle checkbox change
  const handleCheckboxChange = (e) => {
    if(e.target.checked){
      setIncludeHeaders("true")
    } else {
      setIncludeHeaders("false")
    }
  }

  const handleSetColor = (color, param) => {
    if(param === "Background"){
      setHeaderSettings({...headerSettings, backgroundColor: color})
    } else {
      setHeaderSettings({...headerSettings, textColor: color})
    }
  }

  const handleDateSettings = (event) => {
    setDateSettings(event.target.value);
  };

  const getTimezoneOffsetString = () => {
      const date = new Date();
      const offset = -date.getTimezoneOffset();
      const sign = offset >= 0 ? '+' : '-';
      const hours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
      const minutes = String(Math.abs(offset) % 60).padStart(2, '0');
      return `UTC ${sign}${hours}:${minutes}`;
  };

  const getTimezoneLocation = () => {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const fontStyle = {style: {fontSize: 14}}

  return (
    <Dialog onClose={handleClose} open={open} className='settings-dialog' fullScreen>
        <DialogTitle>Settings</DialogTitle>
      <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        
        <DialogContent dividers className='tagsearch-dialog-content'>
        
        <div className='settings-box gray-border'>
        <Typography sx={{fontSize: 14, marginBottom: "15px"}}>Required settings</Typography>
        
        <TextField  
            label={apiUrl.length > 0 ? "Api Url" : "Api Url (format https://domain-name.com/api/v1)"}
            size="small" margin="dense"
            fullWidth={true}
            inputProps={{...fontStyle, style: {backgroundColor: "white"}}}
            InputLabelProps={fontStyle}
            value={apiUrl}
            onChange={(event) => setApiUrl(event.target.value)}
        />
        </div>

        <div className='settings-box gray-border'>
        <Typography sx={{fontSize: 14, marginBottom: "15px"}}>Optional settings for SQL form</Typography>
        <TextField  
          label="Hostname"
          size="small" margin="dense"
          fullWidth={true}
          inputProps={{...fontStyle, style: {backgroundColor: "white"}}}
          InputLabelProps={fontStyle}
          value={hostname}
          onChange={(event) => setHostname(event.target.value)}
        />

        <TextField  
          label="HTTP Path"
          size="small" margin="dense"
          fullWidth={true}
          inputProps={{...fontStyle, style: {backgroundColor: "white"}}}
          InputLabelProps={fontStyle}
          value={httpPath}
          onChange={(event) => setHttpPath(event.target.value)}
        />
        </div>

        <div className='settings-box gray-border'>
        <Typography sx={{fontSize: 14, marginBottom: "15px"}}>Header settings</Typography>
        <FormControlLabel 
          control={
              <Checkbox size="small" checked={String(includeHeaders) === "true"} onChange={(e) => {handleCheckboxChange(e)}}/>
          }
          
          label={<Typography sx={{fontSize: 12}}>Include headers when making request from task pane</Typography>}
        />
        {String(includeHeaders) === "true" &&
          <>
            <div style={{marginTop: "10px"}}>
              <ColorPicker label="Background" color={headerSettings.backgroundColor} setColor={handleSetColor}/>
            </div>
            <div style={{marginTop: "10px"}}>
              <ColorPicker label="Text" color={headerSettings.textColor} setColor={handleSetColor}/>
            </div>
          </>
        }

      </div>
      
      <div className='settings-box gray-border'>
      <Typography sx={{fontSize: 14, marginBottom: "15px"}}>Date settings</Typography>
      <FormControl>
        <RadioGroup
          // row
          aria-labelledby="date-radio-buttons"
          name="date-radio-buttons"
          value={dateSettings}
          onChange={handleDateSettings}
        >
          <FormControlLabel 
            sx={{ '& .MuiFormControlLabel-label': { fontSize: '12px' } }}
            value="user-timezone"
            control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 18}}}/>} 
            label={`My Timezone (${getTimezoneOffsetString() + " " + getTimezoneLocation()})`}
          />
          <FormControlLabel
            sx={{ '& .MuiFormControlLabel-label': { fontSize: '12px' } }}
            value="databricks-table"
            control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 18}}}/>} 
            label="Databricks Source Table Timezone"
          />
          <FormControlLabel
            sx={{ '& .MuiFormControlLabel-label': { fontSize: '12px' } }}
            value="utc"
            control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 18}}}/>} 
            label="UTC"
          />

        </RadioGroup>
      </FormControl>
      </div>

      <div className='settings-box aliceblue-background'>
        <p>Learn more about the RTDIP Add-in:</p>
        <a href="https://ssip-docs.shell.com/exceladdin/about/"><Button>Documentation</Button></a>
      </div>

        

      </DialogContent>

      <DialogActions>
          <Button color="primary" onClick={handleSubmit}>
            Save
          </Button>
      </DialogActions>

    </Dialog>
  );
}

function Settings(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (newSettings) => {
    props.handleSettingsChange(newSettings)
    setOpen(false)
  }

  return (
    <div>
      <IconButton
          onClick={handleClickOpen}
          id="settings-button"
          data-testid="settings-button"
          sx={{
            position: 'absolute',
            left: 8,
            top: 8,
            color: (theme) => theme.palette.grey[300],
          }}
        >
          <SettingsIcon />
        </IconButton>

      <SettingsDialog
        open={open}
        onClose={handleClose}
        handleSubmit={handleSubmit}
        settings={props.settings}
      />
    </div>
  );
}

export default Settings;
